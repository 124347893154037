.containerSection6 {
  width: 100%;
  display: flex;
  background-color: #e5e5e5;


  .content {
    width: 70%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .center {
      align-items: flex-end;
      text-align: center;

      h3 {
        margin: 20px auto
      }
    }

    .line {
      display: flex;
      align-items: center;
      margin: 10px auto;
    }

    .image {
      height: 150px;
      text-align: center;

      img {
        border-radius: 10px;
        height: 100%;
      }
    }

    .border-bottom {
      border-bottom: 1px solid #fff;
      padding: 5px 0px;
    }

    .check {
      padding: 5px 8px;
      background: #3cb371;
      border-bottom: 2px solid #308f5a;
      color: #fff;
      border-radius: 3px;
      margin: 5px 0;
      margin-right: 6px;
      width: auto;
      margin-left: 0 !important;
      display: inline-block;
    }

  }


  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }

}