.containerSection9 {
  width: 100%;
  background-color: #474747;

  .content {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 20px auto;
    gap: 10px;

    .img-container {
      display: flex;
      flex-direction: row;
      gap: 30px;
      flex-wrap: wrap;
      justify-content: center;
      margin: 20px auto;

      .image {
        border: 6px solid #fff;
        border-radius: 10px;
        height: 220px;
        background-color: #fff;

        img {
          width: 100%;
          height: 100%;
          border: 2px solid #fff;
        }
      }
    }


  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }
}