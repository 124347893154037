.container7dcSection5 {
  width: 100%;
  background-color: #ec6331;
  color: #fff;

  .content {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 20px auto;
    gap: 10px;

    .card {
      width: fit-content;
      background-color: #fff;
      padding: 1rem;
      border-radius: 10px;
      margin: 10px auto;
      box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 58%);

      .line {
        display: flex;
        justify-content: center;
        margin: 10px auto;
        text-align: center;
      }

      .withPhoto {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        .textPicture {
          width: 50%;
        }

        .imgCtn {
          width: 45%;
          display: flex;
          justify-content: center;
        }

        .img-food {
          width: 50%;
          border: 5px solid #000;
        }

        .img-treadmill {
          width: 50%;
          border: 5px solid #000;
        }
      }
    }

    .list {
      width: 70%;
      line-height: 1.5rem;

      .line {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;

        .iconSection5 {
          width: 8%;
          height: 8%;
          margin-right: 2rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;

      .list {
        width: 100%;

        .line {
          .iconSection5 {
            width: 15%;
            height: 15%;
          }
        }
      }
    }
  }
}