.footerCtn {
  width: 100%;
  background-color: #606060;

  .content {
    width: 70%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .links {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;

      a {
        color: #0081DB;
        margin: 5px 20px;
      }
    }
  }


  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }

}