.optinCtn {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1E1C1C;
    color: #f0eeee;
    border-bottom: solid 7px #FF7001;
    font-family: Merriweather !important;


    .uppercase {
        text-transform: uppercase;
    }

    .logoCtn {
        margin: 1rem auto;
    }

    .errorForm {
        width: 80%;
        color: #fff;
        text-align: center;
        background-color: red;
        font-weight: 500;
        padding: 10px;
        margin: 0px auto;
    }

    .content {
        width: 100%;
        min-height: calc(100vh - 200px);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border-top: solid 7px #FF7001;


        .header {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                width: 90%;
                font-size: 1.5rem;
                line-height: 2.5rem;
                font-weight: 600;
                margin-bottom: 1rem;
                text-align: center;
            }

            .subtitle {
                width: 90%;
                font-size: 1.1rem;
                line-height: 1.5rem;
                font-weight: 400;
                margin-bottom: 1rem;
                text-align: center;

                .grey {
                    color: #817f7f;
                }
            }
        }

        .formContainer {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            .immersionImg {
                width: 200px;

                .img {
                    width: 100%;
                }

                .imgTxt {
                    font-size: 1rem;
                    text-align: center;
                }
            }

            .formCtn {
                width: 400px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .formTitle {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: .5rem;

                    .textEmail {
                        font-size: .8rem;
                        text-align: center;
                    }

                    .arrow-icon {
                        font-size: 2rem;
                        margin: 0 auto;
                    }
                }

                .form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .input-text {
                        width: 50%;
                        height: 30px;
                        padding: 0 0.5rem;
                    }

                    .checkboxCtn {
                        width: 90%;

                        .checkboxLabel {
                            width: 100%;
                            display: flex;
                            flex-direction: row;

                            .text-check {
                                font-size: 0.9rem;
                                font-weight: 100;
                                text-align: center;
                            }
                        }

                        #checkbox {
                            width: 20px;
                            height: 20px;
                            margin-top: 0.5rem;
                        }

                        .checkboxLabel,
                        #checkbox[type='submit'] {
                            margin-top: 1em;
                        }

                    }

                    .btn {
                        width: 90%;
                        height: auto;
                        margin: 1rem auto;
                        font-size: 1rem;
                        background-color: #0074C7;
                        color: #fff;
                        padding: 5px;
                        border-radius: 5px;
                        text-transform: uppercase;


                        &:hover {
                            background-color: #4089bd;
                        }
                    }
                }
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
    .optinCtn {
        .content {
            .header {
                .title {
                    font-size: 1.7rem;
                    line-height: 2.5rem;
                }

                .subtitle {
                    font-size: 1.5rem;
                    line-height: 2.5rem;
                }
            }

            .formContainer {
                .immersionImg {
                    width: 400px;
                }

                .formCtn {
                    .formTitle {
                        margin-bottom: 1rem;

                        .textEmail {
                            font-size: 1rem;
                        }
                    }

                    .form {
                        .input-text {
                            height: 40px;
                            border-radius: 5px;
                            padding: 0 0.5rem;
                        }

                        .checkboxCtn {
                            .checkboxLabel {
                                .text-check {
                                    font-size: 1rem;
                                }
                            }
                        }

                        .btn {
                            font-size: 1.2rem;
                            padding: 1rem 2rem;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 899px) {

    .optinCtn {
        .content {
            .header {
                .title {
                    font-size: 1.9rem;
                }

                .subtitle {
                    font-size: 1.7rem;
                    line-height: 2.7rem;
                }
            }

            .formContainer {
                .immersionImg {
                    width: 600px;
                }

                .formCtn {
                    .formTitle {
                        margin-bottom: 1rem;

                        .textEmail {
                            font-size: 1.2rem;
                        }
                    }

                    .form {
                        .input-text {
                            font-size: 1.2rem;
                        }

                        .btn {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
    .optinCtn {
        .content {
            .header {
                .title {
                    font-size: 1.9rem;
                }

                .subtitle {
                    font-size: 1.7rem;
                    line-height: 2.7rem;
                }
            }

            .formContainer {
                .immersionImg {
                    width: 500px;
                    margin-right: 2rem;
                }

                .formCtn {
                    width: 400px;

                    .form {

                        .btn {
                            font-size: 1.3rem;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1399px) {
    .optinCtn {
        .content {
            .header {
                .title {
                    font-size: 2.3rem;
                }

                .subtitle {
                    font-size: 1.9rem;
                    line-height: 2.9rem;
                }
            }

            .formContainer {
                .immersionImg {
                    width: 700px;
                }

                .formCtn {
                    // width: 600px;

                    .formTitle {

                        .textEmail {
                            font-size: 1.5rem;
                        }
                    }

                    .form {

                        .btn {
                            font-size: 1.3rem;
                        }
                    }
                }
            }
        }
    }
}