.btn-goCheckout {
  margin: 1rem auto;
  padding: 1rem;
  background-color: #ec6331;
  border-radius: 10px;
  width: fit-content;
  box-shadow: 1px 1px 5px 1px #838282a4;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  color: #fff;

  .arrow {
    color: #ec6331;
    background-color: #fff;
    border-radius: 50px;
    padding: 5px 8px;
    margin: 0px 5px;
  }

  @media screen and (max-width: 400px) {
    .arrow {
      display: none;
    }
  }
}

.btn-goCheckout-white {
  background-color: #fff;
  color: black !important;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 10px;
  width: fit-content;
  box-shadow: 1px 1px 5px 1px #838282a4;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;

  .arrow {
    color: #fff;
    background-color: #ec6331;
    border-radius: 50px;
    padding: 5px 8px;
    margin: 0px 5px;
  }

  @media screen and (max-width: 400px) {
    .arrow {
      display: none;
    }
  }
}