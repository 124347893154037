.tyCtn {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1E1C1C !important;
    color: #f0eeee;
    border-bottom: solid 5px #FF7001;


    .uppercase {
        text-transform: uppercase;
    }



    .logoCtn {
        width: 100%;
        margin: 1rem auto;
        border-bottom: solid 5px #FF7001;
        display: flex;

        .logo {
            margin: 0 auto 1rem auto;
        }
    }

    .content {
        width: 100%;
        min-height: calc(100vh - 200px);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .header {
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .title {
                width: 90%;
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 600;
                text-align: center;
            }

            .subTitle {
                width: 90%;
                font-size: .9rem;
                line-height: 1.2rem;
                font-weight: 400;
                text-align: center;
            }
        }

        .videoWrapper {
            width: 300px;
            height: 150px;
            position: relative;
    
            .video {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .imgCtn {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1rem auto;
    
            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media (min-width: 599px) {
    .tyCtn .content {
        .header {
            .title {
                font-size: 1.5rem;
                line-height: 2.5rem;
            }

            .subTitle {
                font-size: 1.2rem;
            }
        }

        .videoWrapper {
            width: 500px;
            height: 400px;
        }

        .imgCtn {
            width: 500px;
        }
    }
}

@media (min-width: 899px) {
    .tyCtn .content {
        .header {
            .title {
                font-size: 1.7rem;
                line-height: 2.7rem
            }

            .subTitle {
                font-size: 1.5rem;
            }
        }

        .videoWrapper {
            width: 600px;
            height: 500px;
        }
    }
}

@media (min-width: 1199px) {
    .tyCtn .content {
        .header {
            .title {
                font-size: 2rem;
                line-height: 3rem;
            }

            .subTitle {
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }
        }

        .videoWrapper {
            width: 900px;
            height: 500px;
            margin: 2rem auto;
        }
    }
}

