.bannerCtn {
  width: 100%;
  background-color: rgb(236, 225, 49);

  .content {
    width: 70%;
    text-align: center;
    margin: 15px auto;
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;

    }
  }
}