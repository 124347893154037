.container7dcSection7 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  background-color: #e5e5e5;

  .content {
    width: 70%;
    text-align: center;
    display: flex;
    margin: 20px auto;
    flex-direction: column;
    gap: 5px;
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }

  .image-desktop {
    margin: 1rem auto;
    text-align: center;

    img {
      width: 70%;
    }
  }

  @media screen and (max-width: 768px) {
    .image-desktop {
      display: none;
    }
  }

  .image-mobile {
    margin: auto;
    text-align: center;

    img {
      width: 150px;
      margin: 20px auto 10px auto;
    }
  }

  @media screen and (min-width: 768px) {
    .image-mobile {
      display: none;
    }
  }

  .line {
    display: flex;
    align-items: center;
    margin: 10px auto;
  }

  .check {
    padding: 5px 8px;
    background: #3cb371;
    border-bottom: 2px solid #308f5a;
    color: #fff;
    border-radius: 3px;
    margin: 5px 0;
    margin-right: 6px;
    width: auto;
    margin-left: 0 !important;
    display: inline-block;
  }

  .border-bottom {
    border-bottom: 1px solid rgb(117, 117, 117);
  }
}
