.container7dcSection6 {
  width: 100%;
  background-image: url("../../../images/TwentyEightDays/bg-nico.png");
  background-position: 25% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3% 0;

  .content {
    width: 70%;
    min-height: 600px;
    margin: 20px auto;
    text-align: center;

    .container-title {
      display: flex;
      justify-content: center;

      .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      align-self: center;
      gap: 20px;

      .text {
        max-width: 80%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        line-height: 1.5rem;
      }

      @media screen and (max-width: 1071px) {
        .text {
          max-width: 100%;
        }
      }

      .image {
        height: 300px;

        img {
          height: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }
}
