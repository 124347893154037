.containerSection7 {
  width: 100%;
  background-color: #EC6331;

  .btnContain {
    width: 100%;
    padding: 10px;
    background: #fff
  }

  .content {
    width: 70%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;


    gap: 20px;

    .container-card {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .card {
        background-color: #fff;
        box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 58%);
        padding: 15px;
        border-radius: 10px;
        width: 30%;
        min-width: 290px;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        gap: 10px;

        img {
          width: 128px;
        }
      }

      @media screen and (max-width: 850px) {

        .card {
          width: 100%;
        }
      }
    }

    .image {
      height: 100px;

      img {
        height: 100%;
      }
    }

    .card-week {

      background-color: #fff;
      box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 58%);
      padding: 15px;
      border-radius: 10px;
      width: 100%;
      text-align: left;

      .line {
        display: flex;
        align-items: center;
        margin: 10px auto;
      }

      .check {
        padding: 5px 8px;
        background: #3cb371;
        border-bottom: 2px solid #308f5a;
        color: #fff;
        border-radius: 3px;
        margin: 5px 0;
        margin-right: 6px;
        width: auto;
        margin-left: 0 !important;
        display: inline-block;
      }

      .border-bottom {
        border-bottom: 1px solid rgb(117, 117, 117);
      }

    }

  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }
}