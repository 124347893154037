.containerSection3 {
  width: 100%;
  border-bottom: #ec6331 40px solid;
  background: radial-gradient(circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(201, 201, 201, 1) 64%);

  .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 20px auto 5px auto;


    .card {
      width: 100%;
      background-color: #fff;
      padding: 1rem;
      border-radius: 10px;
      margin: 10px auto;
      box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 58%);
    }

    .bottom {
      margin: 1rem;
    }

    .line {
      display: flex;
      align-items: center;
      margin: 10px auto;
    }

    .check {
      padding: 5px 8px;
      background: #3cb371;
      border-bottom: 2px solid #308f5a;
      color: #fff;
      border-radius: 3px;
      margin: 5px 0;
      margin-right: 6px;
      width: auto;
      margin-left: 0 !important;
      display: inline-block;
    }

    .border-bottom {
      border-bottom: 1px solid rgb(117, 117, 117);
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }
}