.container7dcVslSection1 {
  width: 100%;
  height: 100%;
  background-image: url("../../../images/TwentyEightDays/background-nico.png");
  background-position: 50% 25%;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-grow: 1;
  align-items: center;

  .content {
    width: 70%;
    margin: 20px auto;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .logo {
      max-width: 95%;

      img {
        width: 100%;
      }
    }

    .video-ctn {
      width: 90%;
      height: 400px;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;

      .video-ctn {
        width: 98%;
        height: 200px;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}