.cookiesCtn {
  width: 100%;
  background-color: rgba(71, 71, 71, 0.64);

  .content {
    width: 70%;
    margin: auto;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
    justify-content: center;
    margin: 5px auto;

  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }

  .btn {
    margin: 10px;
    background-color: #fff;
    color: rgb(71, 71, 71);
    border-radius: 50px;
    padding: 15px;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .content {
    margin: 0px auto;
    width: 90%;

    .txt {
      width: 100%;
      text-align: center;
    }

    .btn {
      margin: 5px;
    }
  }
}