.Immersionformctn {
    width: 100vw;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1E1C1C;
    color: #f0eeee;
    border-bottom: solid 7px #FF7001;
    font-family: Merriweather !important;
    padding-bottom: 16px;

    .uppercase {
        text-transform: uppercase;
    }

    .btn {
        width: fit-content;
        height: 50px;
        margin: 1rem auto;
        background-color: #0074C7;
        color: #fff;
        padding: 0.5rem 2rem;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: #4089bd;
        }

    }

    .errorForm {
        width: 80%;
        color: #fff;
        text-align: center;
        background-color: red;
        font-weight: 500;
        padding: 10px;
        border-radius: 10px;
        margin: 0px auto;
    }

    .errorInput {
        border: red 2px solid;
        width: fit-content;
        border-radius: 10px;
        padding: 5px;
    }

    h1 {
        font-size: 1.2rem !important;
        color: #030d12;
        top: 0;
        left: 0;
        text-transform: uppercase;
    }



    .logoCtn {
        width: 100%;
        margin: 1rem auto;
        border-bottom: solid 7px #FF7001;
        display: flex;

        .logo {
            margin: 0 auto 1rem auto;
        }
    }

    .titleForm {
        width: 90%;
        font-size: 1.2rem;
        line-height: 1.5rem;
        font-weight: bold;
        margin: 1rem auto;
        text-align: center;
    }

    .formPartial {
        width: 100%;
        margin-bottom: 2rem;
    }

    .formInput {
        width: 60%;
        outline: 1px solid #1E1C1C;
    }

    .formTextArea {
        width: 100% !important;
    }

    .question {
        font-size: 1rem;
        margin: 0 0 0.5rem 0;
    }


    .videoWrapper {
        width: 300px;
        height: 150px;
        position: relative;

        .video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    //-------------------------------------  CONTENT  -------------------------------------//

    .content {
        width: 100%;
        min-height: calc(100vh - 200px);
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;


        .sante,
        .argent,
        .relation,
        .mental,
        .general {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            color: #030d12;
            background-color: #fff;
            border-bottom: dashed 1px rgb(184, 183, 183);
            padding: 0 1rem;

            .btnRadioCtn {
                display: flex;
                flex-direction: row;

                label {
                    font-size: 1rem;
                    margin: 0 1rem 0 0.5rem;
                }
            }

        }

        input#sante-a.sante,
        input#sante-b.sante {
            width: 15px;
        }

        .redStar {
            color: red;
        }

        .relation {
            .formRelation {
                .btnRadioCtn {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    #situationLabel {
                        width: 100px;
                    }
                }
            }

            .selectCtn {
                width: 60%;

                select {
                    width: 100%;
                }
            }

        }

        .general {
            .nameInfosCtn {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-items: flex-start;

                .nameInfos {
                    width: 150px;
                    margin-right: 0.5rem;
                    text-align: start;

                    input {
                        width: 100%;
                        margin: 0 0 0.5rem 0;
                    }
                }
            }
        }

        .buttonCtn {
            width: 80%;
            background-color: #fff;
            padding: 1rem;

            button {
                outline: 1px solid #1E1C1C;

            }
        }

    }
}

@media (min-width: 320px) {
    .Immersionformctn {
        .content {
            .relation {
                .formRelation {
                    .btnRadioCtn {
                        width: 320px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        #situationLabel {
                            width: 320px;
                        }
                    }
                }
            }
        }
    }
}



/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
    .Immersionformctn {
        .videoWrapper {
            width: 500px;
            height: 400px;
        }

        .content {
            .relation {
                .formRelation {
                    .btnRadioCtn {
                        width: 550px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        #situationLabel {
                            width: 100px;
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 899px) {
    .Immersionformctn {
        .videoWrapper {
            width: 600px;
            height: 500px;
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
    .Immersionformctn {
        .videoWrapper {
            width: 900px;
            height: 500px;
            margin: 2rem auto;
        }
    }
}