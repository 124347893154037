.container7dcSection1 {
  width: 100%;
  background-image: url("../../../images/TwentyEightDays/background-nico.png");
  background-position: 50% 25%;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    width: 70%;
    margin: 20px auto;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .logo {
      max-width: 95%;

      img {
        width: 100%;
      }
    }

    .video-ctn {
      width: 70%;
      font-size: 0.9em;
      font-weight: 500;

      video {
        width: 99%;
        height: auto;
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;

      .video-ctn {
        width: 100%;
      }
    }
  }
}
