.containerSection5 {
  width: 100%;
  background-color: #EC6331;
  color: #fff;

  .content {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 20px auto;
    gap: 10px;

    .list {
      width: 70%;

      .line {
        display: flex;
        align-items: center;
        text-align: left;
        margin: 10px auto;
      }

      .check {
        padding: 5px 8px;
        background: #f9f9f9;
        border: 1px solid #eee;
        border-bottom: 2px solid #eee;
        border-radius: 4px;
        margin: 5px 0;
        margin-right: 6px;
        width: auto;
        margin-left: 0 !important;
        display: inline-block;
        color: #474747;
      }

      .border-bottom {
        border-bottom: 1px solid rgb(255, 255, 255);
      }

    }

  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;

      .list {
        width: 100%;
      }
    }
  }
}