.container7dcSection9 {
  width: 100%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(201, 201, 201, 1) 64%
  );

  .content {
    width: 50%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    .container-text {
      width: 85%;

      .titleSection4 {
        margin-bottom: 1.5rem;
      }
    }

    .container-img {
      width: 150px;

      img {
        width: 100%;
      }
    }
  }

  .line {
    display: flex;
    align-items: center;
    margin: 10px auto;

    p {
      text-align: left;
    }
  }

  .border-bottom {
    border-bottom: 1px solid rgb(117, 117, 117);
  }

  .check {
    padding: 5px 8px;
    background: #3cb371;
    border-bottom: 2px solid #308f5a;
    color: #fff;
    border-radius: 3px;
    margin: 5px 0;
    margin-right: 6px;
    width: auto;
    margin-left: 0 !important;
    display: inline-block;
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }

  @media screen and (max-width: 1520px) {
    .content {
      .container-text {
        width: 100%;
      }
    }
  }
}
