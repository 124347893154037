.footerCtn {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1E1C1C;

    .copyright {
        font-size: 1.3rem;
        font-weight: 400;
        margin-bottom: 2rem;
        color: rgb(228, 223, 223);
    }

    .links {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #0002EC;

        a {
            margin-bottom: 0.5rem;
            border-bottom: solid 1px transparent;
            transition: all 0.3s ease-in-out;
        }
    }


}