.containerSection4 {
  width: 100%;
  background: radial-gradient(circle,
      rgba(255, 255, 255, 1) 0%,
      rgba(201, 201, 201, 1) 64%);


  .content {
    width: 70%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    .container-text {
      width: 85%;
    }

    .container-img {
      width: 150px;

      img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;

    }

  }

  @media screen and (max-width: 1520px) {
    .content {
      .container-text {
        width: 100%;
      }
    }

  }
}