.container7dcSection3 {
  width: 100%;
  border-bottom: #ec6331 40px solid;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(201, 201, 201, 1) 64%
  );

  .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 20px auto 5px auto;

    .card {
      width: 100%;
      background-color: #fff;
      padding: 1rem;
      border-radius: 10px;
      margin: 10px auto;
      box-shadow: 5px 5px 15px 1px rgb(0 0 0 / 58%);

      .line {
        display: flex;
        justify-content: center;
        margin: 10px auto;
        text-align: center;
      }

      .withPhoto {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        .textPicture {
          width: 50%;
        }

        .imgCtn {
          width: 45%;
          display: flex;
          justify-content: center;
        }

        .img-food {
          width: 50%;
          border: 5px solid #000;
        }

        .img-treadmill {
          width: 50%;
          border: 5px solid #000;
        }
      }
    }

    .bottom {
      margin: 1rem;
    }

    .orange {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;

      .card {
        .withPhoto {
          .textPicture {
            width: 100%;
          }

          .imgCtn {
            width: 90%;
          }

          .img-food {
            width: 50%;
          }

          .img-treadmill {
            width: 50%;
          }
        }
      }

      .orange {
        font-size: 1.5rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .content {
      width: 70%;

      .card {
        .withPhoto {
          width: 60%;

          .textPicture {
            width: 60%;
          }

          .imgCtn {
            width: 35%;
            display: flex;
            justify-content: center;
          }

          .img-food {
            width: 60%;
            border: 5px solid #000;
          }

          .img-treadmill {
            width: 60%;
            border: 5px solid #000;
          }
        }
      }

      .orange {
        font-size: 1.5rem;
      }
    }
  }
}
