.container7dcSection8 {
  width: 100%;
  background-color: #ec6331;
  color: #fff;

  .content {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 20px auto;
    gap: 10px;

    .list {
      width: 70%;
      text-align: center;
      line-height: 1.5rem;

      p,
      h3 {
        text-align: center;
      }

      .iconSection8 {
        width: 20%;
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;

      .list {
        width: 100%;
      }
    }
  }
}