@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

// @font-face {
//   font-family: "Pirulen";
//   src: local("pirulen-rg"),
//     url("/fonts/pirulen/pirulen-rg.woff") format("font-woff"),
//     url("/fonts/pirulen/pirulen-rg.woff2") format("font-woff2"),
//     url("/fonts/pirulen/pirulen-rg.ttf") format("font-ttf"),
//     url("/fonts/pirulen/pirulen-rg.otf") format("font-otf");
//   font-display: swap;
//   font-weight: normal;
//   font-style: normal;
// }

@import "./reset";


body,
html,
.root {
  font-family: "Poppins";
  font-weight: 300;
  width: 100vw;
  height: 100vh;
  margin: none;
  padding: none;
}



ol,
ul {
  padding-left: 30px;
}

//text global
.otherContent,
.content {
  font-size: 1.2rem;

  .text-small {
    font-size: 0.9rem;
    font-weight: 400;
  }

  p {
    margin: 10px;
  }

  .white {
    color: #fff;
  }

  .black {
    color: #000;
  }

  .grey {
    color: #474747;
  }

  .orange {
    color: #ec6331;
  }

  strong {
    font-weight: 500;
  }

  .upperCase {
    text-transform: uppercase;
  }

  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .check {
    font-size: 1.5rem;
  }

  .bg-grey {
    background-color: #474747;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
  }

  .bg-white {
    background-color: #fff;
    color: #474747;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
  }

  .bg-flou {
    background-color: rgba(255, 255, 255, 0.415);
    color: #474747;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
  }

  .bg-orange {
    background-color: #ec6331;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
  }

  .underline {
    text-decoration: underline;
  }
}

@media screen and (max-width: 768px) {

  .otherContent,
  .content {
    font-size: 1rem;

    h1 {
      font-size: 1.7rem;
    }

    h2 {
      font-size: 1.4rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    .check {
      font-size: 1.1rem;
    }
  }
}