.containerSection2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  background-color: #e5e5e5;

  .content {
    width: 70%;
    text-align: center;
    display: flex;
    margin: 20px auto;
    flex-direction: column;
    gap: 5px;

  }

  @media screen and (max-width: 768px) {
    .content {
      width: 90%;
    }
  }

  .image-desktop {
    margin: 1rem auto;
    text-align: center;

    img {
      width: 70%;
    }
  }

  @media screen and (max-width: 768px) {
    .image-desktop {
      display: none;
    }
  }

  .image-mobile {
    margin: auto;
    text-align: center;

    img {
      width: 150px;
      margin: 20px auto 10px auto;
    }
  }

  @media screen and (min-width: 768px) {
    .image-mobile {
      display: none;
    }
  }
}