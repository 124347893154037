.tyCtn {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(3, 13, 18);
    color: #f0eeee;

    .uppercase {
        text-transform: uppercase;
    }

    .orange {
        color: #ec6331;
        font-weight: bold;
    }

    .logoCtn {
        margin: 1rem auto 0 auto;
    }


    .content {
        width: 100%;
        min-height: calc(100vh - 200px);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .header {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                width: 90%;
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 600;
                text-align: center;
            }

            .subTitle {
                width: 90%;
                font-size: .9rem;
                line-height: 1.2rem;
                font-weight: 400;
                text-align: center;
            }
        }

        .videoWrapper {
            width: 300px;
            height: 150px;
            position: relative;

            .video {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .imgCtn {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1rem auto;

            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }


    }
}

.uppercase {
    text-transform: uppercase;
}

.btn {
    width: fit-content;
    height: 50px;
    margin: 15px;
    background-color: #0074C7;
    color: #fff;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: #4089bd;
    }

}

@media (min-width: 599px) {
    .tyCtn .content {

        .header {

            .title {
                font-size: 1.5rem;
                line-height: 2.5rem;
            }


            .subTitle {
                font-size: 1.2rem;
            }
        }

        .videoWrapper {
            width: 500px;
            height: 400px;
        }

        .imgCtn {
            width: 500px;
        }
    }
}

@media (min-width: 899px) {
    .tyCtn .content {

        .header {

            .title {
                font-size: 1.7rem;
                line-height: 2.7rem
            }

            .subTitle {
                font-size: 1.5rem;
            }
        }

        .videoWrapper {
            width: 600px;
            height: 500px;
        }
    }
}

@media (min-width: 1199px) {
    .tyCtn .content {

        .header {

            .title {
                font-size: 2rem;
                line-height: 3rem;
            }

            .subTitle {
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }
        }

        .videoWrapper {
            width: 900px;
            height: 500px;
            margin: 2rem auto;
        }
    }
}